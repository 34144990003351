export const SHOW_SPINNING = "SHOW_SPINNING";
export const SHOW_TOTALS_SPINNING = "SHOW_TOTALS_SPINNING";

export const USER_ERROR = "USER_ERROR";
export const USER_REGISTRATION = "USER_REGISTRATION";
export const CREATE_PUBLISHER = "CREATE_PUBLISHER";
export const ERROR_CREATE_PUBLISHER = "ERROR_CREATE_PUBLISHER";
export const GET_USERS = "GET_USERS";
export const GET_USER_ERROR = "GET_USER_ERROR";
export const DELETE_USER = "DELETE_USER";
export const GET_USER = "GET_USER";
export const ERROR_USER = "ERROR_USER";
export const GET_ALL_USERS = "GET_ALL_USERS";
/**
 * Get from the existing site
 */
export const GET_EXISTING_ALBUM = "GET_EXISTING_ALBUM";
export const ERROR_ALBUM_LIST = "ERROR_ALBUM_LIST";

export const GET_ALBUM_BYID = "GET_ALBUM_BYID";
export const ERROR_ALBUM_BYID = "ERROR_ALBUM_BYID";

export const GET_ALBUM_BYRANGEID = "GET_ALBUM_BYRANGEID";
export const ERROR_ALBUM_BYRANGEID = "ERROR_ALBUM_BYRANGEID";
/**
 * Add/Delete Album
 */
export const ADD_ALBUM = "ADD_ALBUM";
export const DELETE_ALBUM = "DELETE_ALBUM";
export const UPDATE_ALBUM = "UPDATE_ALBUM";
export const ALL_ARTISTS = "ALL_ARTISTS";

/**
 * Getting the registered album list
 */
export const GET_REGISTERED_ALBUMS = "GET_REGISTERED_ALBUMS";
export const ERROR_REGISTERED_ALBUM_LIST = "ERROR_REGISTERED_ALBUM_LIST";

/**
 * Getting all the registered album list
 */
export const GET_ALL_REGISTERED_ALBUMS = "GET_ALL_REGISTERED_ALBUMS";
export const ERROR_ALL_REGISTERED_ALBUM_LIST = "ERROR_ALL_REGISTERED_ALBUM_LIST";

/**
 * Assigning Albums
 */
export const ERROR_ASSIGN_ALBUMS = "ERROR_ASSIGN_ALBUMS";
export const ASSIGN_ALBUMS = "ASSIGN_ALBUMS";

/**
 * Getting Track list by publisher_id and album_id
 */
export const GET_TRACK_LIST = "GET_TRACK_LIST";
export const ERROR_TRACK_LIST = "ERROR_TRACK_LIST";
export const TRACK_UPDATE_PAYMENT = 'TRACK_UPDATE_PAYMENT';

export const UPDATE_PAID = "UPDATE_PAID";
export const GET_SEARCH_USERS = "GET_SEARCH_USERS";
export const USER_LOGOUT = "USER_LOGOUT";
export const DELETE_HISTORY_ERROR = "DELETE_HISTORY_ERROR";
export const DELETE_HISTORY = "DELETE_HISTORY";
export const GET_SEARCH_ALBUMS = "GET_SEARCH_ALBUMS";
export const MSG_SEARCH_ALBUMS = "MSG_SEARCH_ALBUMS";

export const GET_TOTAL_OWED = "GET_TOTAL_OWED";
export const MSG_TOTAL_OWED = "MSG_TOTAL_OWED";

export const GET_PLAY_LIST = "GET_PLAY_LIST";
export const ERROR_PLAY_LIST ="ERROR_PLAY_LIST";

export const GET_MOST_PLAYED="GET_MOST_PLAYED";
export const ERROR_GET_MOST_PLAYED="ERROR_GET_MOST_PLAYED";

export const GET_REVENUECAT="GET_REVENUECAT";
export const ERROR_GET_REVENUECAT="ERROR_GET_REVENUECAT";

export const SEND_EMAIL="SEND_EMAIL";