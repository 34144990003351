/**
 * The file for album creating/assigning, track editing/assigning
 */

import axios from "axios";
import config from "../../../config/index";
import {
    SHOW_SPINNING,
    GET_EXISTING_ALBUM,
    ERROR_ALBUM_LIST,
    DELETE_ALBUM,
    UPDATE_ALBUM,
    ADD_ALBUM,
    ALL_ARTISTS,

    GET_REGISTERED_ALBUMS,
    ERROR_REGISTERED_ALBUM_LIST,

    ASSIGN_ALBUMS,
    ERROR_ASSIGN_ALBUMS,

    GET_ALBUM_BYID,
    ERROR_ALBUM_BYID,
    
    GET_ALBUM_BYRANGEID,
    ERROR_ALBUM_BYRANGEID,

    GET_ALL_REGISTERED_ALBUMS,
    ERROR_ALL_REGISTERED_ALBUM_LIST,

} from "../types/types";

const API_URL =  config.IS_DEV ? config.DEV_API_URL : config.SIM_API_URL;

export const reset = () => dispatch => {
    dispatch({
        type: ERROR_ALBUM_LIST,
        payload: '',
    });
    dispatch({
        type: ADD_ALBUM,
        payload: '',
    });
    dispatch({
        type: DELETE_ALBUM,
        payload: '',
    });
    dispatch({
        type: UPDATE_ALBUM,
        payload: '',
    });
    dispatch({
        type: ERROR_ALBUM_BYID,
        payload: '',
    });
};

export const resetList = () => dispatch => {
    dispatch({
        type: ERROR_REGISTERED_ALBUM_LIST,
        payload: '',
    });
    dispatch({
        type: ASSIGN_ALBUMS,
        payload: '',
    });
    dispatch({
        type: ERROR_ASSIGN_ALBUMS,
        payload: '',
    });
};

export const getAlbumListFromSite = (data) => dispatch => {
    dispatch({type: SHOW_SPINNING, payload: true});
    axios
        .post(API_URL + "/api/albums/get-albums-jewishmusic", data)
        .then(res => {
            dispatch({type: SHOW_SPINNING, payload: false});
            dispatch({
                type: GET_EXISTING_ALBUM,
                payload: res.data.results,
            });
        })
        .catch(err => {
            dispatch({type: SHOW_SPINNING, payload: false});
            dispatch({
                type: ERROR_ALBUM_LIST,
                payload: err.response ? err.response.data.msg : {error: "error"},
            });
        })
};

/**
 * Getting data from one album_id
 */
export const getAlbumByIdFromSite = (data) => dispatch => {
    dispatch({type: SHOW_SPINNING, payload: true});
    axios
        .post(API_URL + "/api/albums/get-byid-jewishmusic", data)
        .then(res => {
            dispatch({type: SHOW_SPINNING, payload: false});
            dispatch({
                type: GET_ALBUM_BYID,
                payload: res.data.results,
            });
        })
        .catch(err => {
            dispatch({type: SHOW_SPINNING, payload: false});
            dispatch({
                type: ERROR_ALBUM_BYID,
                payload: err.response ? err.response.data.msg : {error: "error"},
            });
        })
};

export const getAlbumByRangeIdFromSite = (data) => dispatch =>{
    dispatch({type: SHOW_SPINNING, payload: true});
    axios
       .post(API_URL + "/api/albums/get-by-range-id-jewishmusic", data)
       .then(res => {
            dispatch({type: SHOW_SPINNING, payload: false});
            dispatch({
                type: GET_ALBUM_BYRANGEID,
                payload: res.data.results,
            });
        })
       .catch(err => {
            dispatch({type: SHOW_SPINNING, payload: false});
            dispatch({
                type: ERROR_ALBUM_BYRANGEID,
                payload: err.response? err.response.data.msg : {error: "error"},
            });
        })
}
export const addAlbumFromSite = (data) => dispatch => {
    dispatch({type: SHOW_SPINNING, payload: true});
    axios
        .post(API_URL + "/api/albums/add-album", data)
        .then(res => {
            dispatch({type: SHOW_SPINNING, payload: false});
            dispatch({
                type: ADD_ALBUM,
                payload: res.data.msg,
            });
        })
        .catch(err => {
            dispatch({type: SHOW_SPINNING, payload: false});
            dispatch({
                type: ADD_ALBUM,
                payload: err.response ? err.response.data.msg : {error: "error"},
            });
        })
};

export const addTrackFromSite = (data) => dispatch => {
    dispatch({type: SHOW_SPINNING, payload: true});
    axios
        .post(API_URL + "/api/albums/add-track", data)
        .then(res => {
            dispatch({type: SHOW_SPINNING, payload: false});
            dispatch({
                type: ADD_ALBUM,
                payload: res.data.msg,
            });
        })
        .catch(err => {
            dispatch({type: SHOW_SPINNING, payload: false});
            dispatch({
                type: ADD_ALBUM,
                payload: err.response ? err.response.data.msg : {error: "error"},
            });
        })
};

export const deleteAlbum = (data) => dispatch => {
    dispatch({type: SHOW_SPINNING, payload: true});
    axios
        .post(API_URL + "/api/albums/delete-album", data)
        .then(res => {
            dispatch({type: SHOW_SPINNING, payload: false});
            dispatch({
                type: DELETE_ALBUM,
                payload: res.data.msg,
            });
        })
        .catch(err => {
            dispatch({type: SHOW_SPINNING, payload: false});
            dispatch({
                type: DELETE_ALBUM,
                payload: err.response ? err.response.data.msg : {error: "error"},
            });
        })
};
export const getAllArtists = (data, callback) => dispatch => {
    dispatch({type: SHOW_SPINNING, payload: true});
    axios
        .post(API_URL + "/api/albums/all-artists", data)
        .then(res => {
            dispatch({type: SHOW_SPINNING, payload: false});
            dispatch({
                type: ALL_ARTISTS,
                payload: res.data.artists,
            });
        })
        .catch(err => {
            dispatch({type: SHOW_SPINNING, payload: false});
            dispatch({
                type: ALL_ARTISTS,
                payload: err.response ? err.response.data.msg : {error: "error"},
            });
        }).finally(() => {
            if (callback) callback();
        });
};
export const updateAlbum = (data, callback) => dispatch => {
    dispatch({type: SHOW_SPINNING, payload: true});
    axios
        .post(API_URL + "/api/albums/update-album", data)
        .then(res => {
            dispatch({type: SHOW_SPINNING, payload: false});
            dispatch({
                type: UPDATE_ALBUM,
                payload: res.data.msg, // data.album
            });
        })
        .catch(err => {
            dispatch({type: SHOW_SPINNING, payload: false});
            dispatch({
                type: UPDATE_ALBUM,
                payload: err.response ? err.response.data.msg : {error: "error"},
            });
        }).finally(() => {
            if (callback) callback();
        });
};
export const deleteTrack = (data, callback) => dispatch => {
    dispatch({type: SHOW_SPINNING, payload: true});
    axios
        .post(API_URL + "/api/albums/delete-track", data)
        .then(res => {
            dispatch({type: SHOW_SPINNING, payload: false});
            dispatch({
                type: DELETE_ALBUM,
                payload: res.data.msg,
            });
        })
        .catch(err => {
            dispatch({type: SHOW_SPINNING, payload: false});
            dispatch({
                type: DELETE_ALBUM,
                payload: err.response ? err.response.data.msg : {error: "error"},
            });
        }).finally(() => {
            if (callback) callback();
        });
};
/**
 * Getting the registered album list
 */
export const getRegisteredAlbumList = (data) => dispatch => {
    dispatch({type: SHOW_SPINNING, payload: true});
    axios
        .post(API_URL + "/api/albums/get-registered-albums", data)
        .then(res => {
            dispatch({type: SHOW_SPINNING, payload: false});
            dispatch({
                type: GET_REGISTERED_ALBUMS,
                payload: res.data.results,
            });
        })
        .catch(err => {
            dispatch({type: SHOW_SPINNING, payload: false});
            dispatch({
                type: ERROR_REGISTERED_ALBUM_LIST,
                payload: err.response ? err.response.data.msg : {error: "error"},
            });
        })
};

/**
 * Searching the registered album list
 */
export const searchRegisteredAlbum = (data) => dispatch => {
    dispatch({type: SHOW_SPINNING, payload: true});
    axios
        .post(API_URL + "/api/albums/search-registered-album", data)
        .then(res => {
            dispatch({type: SHOW_SPINNING, payload: false});
            dispatch({
                type: GET_REGISTERED_ALBUMS,
                payload: res.data.results,
            });
        })
        .catch(err => {
            dispatch({type: SHOW_SPINNING, payload: false});
            dispatch({
                type: ERROR_REGISTERED_ALBUM_LIST,
                payload: err.response ? err.response.data.msg : {error: "error"},
            });
        })
};
/**
 * Assigning albums
 */
export const assignAlbumsToUser = (data) => dispatch => {
    dispatch({type: SHOW_SPINNING, payload: true});
    axios
        .post(API_URL + "/api/albums/assign-albums", data)
        .then(res => {
            dispatch({type: SHOW_SPINNING, payload: false});
            dispatch({
                type: ASSIGN_ALBUMS,
                payload: res.data.msg,
            });
        }).catch(err => {
        dispatch({type: SHOW_SPINNING, payload: false});
        dispatch({
            type: ERROR_ASSIGN_ALBUMS,
            payload: err.response ? err.response.data.msg : {error: "error"},
        });
    })
};

/**
 * Assigning all albums
 */

export const assignAllAlbumsToUser = (data) => dispatch => {
    dispatch({type: SHOW_SPINNING, payload: true});
    axios
        .post(API_URL + "/api/albums/assign-all-albums", data)
        .then(res => {
            dispatch({type: SHOW_SPINNING, payload: false});
            dispatch({
                type: ASSIGN_ALBUMS,
                payload: res.data.msg,
            });
        }).catch(err => {
        dispatch({type: SHOW_SPINNING, payload: false});
        dispatch({
            type: ERROR_ASSIGN_ALBUMS,
            payload: err.response ? err.response.data.msg : {error: "error"},
        });
    })
};


/**
 * Getting the assigned album list to the publisher
 */
export const getAssignedAlbumsById = (data) => dispatch => {
    dispatch({type: SHOW_SPINNING, payload: true});
    axios
        .post(API_URL + "/api/albums/get-assigned-albums", data)
        .then(res => {
            dispatch({type: SHOW_SPINNING, payload: false});
            dispatch({
                type: GET_REGISTERED_ALBUMS,
                payload: res.data.results,
            });
        })
        .catch(err => {
            dispatch({type: SHOW_SPINNING, payload: false});
            dispatch({
                type: ERROR_REGISTERED_ALBUM_LIST,
                payload: err.response ? err.response.data.msg : {error: "error"},
            });
        })
};
/**
 * Getting the assigned album list to the publisher
 */
export const getAllAssignedAlbumsById = (data) => dispatch => {
    dispatch({type: SHOW_SPINNING, payload: true});
    axios
        .post(API_URL + "/api/albums/get-assigned-albums", {...data, returnAll: true})
        .then(res => {
            dispatch({type: SHOW_SPINNING, payload: false});
            dispatch({
                type: GET_ALL_REGISTERED_ALBUMS,
                payload: {id: data.id, data: res.data.results},
            });
        })
        .catch(err => {
            dispatch({type: SHOW_SPINNING, payload: false});
            dispatch({
                type: ERROR_ALL_REGISTERED_ALBUM_LIST,
                payload: err.response ? {id: data.id, error: err.response.data.msg} : {id: data.id,error: {error: "error"}},
            });
        })
};
/**
 * Assigning albums
 */
export const unassignAlbumsToUser = (data) => dispatch => {
    dispatch({type: SHOW_SPINNING, payload: true});
    axios
        .post(API_URL + "/api/albums/unassign-albums-user", data)
        .then(res => {
            dispatch({type: SHOW_SPINNING, payload: false});
            dispatch({
                type: ASSIGN_ALBUMS,
                payload: res.data.msg,
            });
        }).catch(err => {
        dispatch({type: SHOW_SPINNING, payload: false});
        dispatch({
            type: ERROR_ASSIGN_ALBUMS,
            payload: err.response ? err.response.data.msg : {error: "error"},
        });
    })
};


